<template>
  <div>
    <v-btn
      bottom
      class="secondary"
      dark
      fab
      fixed
      right
      @click="dialog = !dialog"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" width="900px">
      <v-card>
        <v-form ref="form" v-model="valid">
          <v-card-title class="primary darken-2 white--text justify-center">
            Invite Vendor
          </v-card-title>
          <v-container class="mx-auto">
            <v-row class="mt-5 mx-3">
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="companyName"
                  prepend-icon="mdi-store"
                  label="Company Name"
                  :rules="[required('Company Name')]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="companyEmail"
                  prepend-icon="mdi-email"
                  label="Company Email"
                  :rules="[required('Email'), emailFormat()]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="phoneNumber"
                  prepend-icon="mdi-phone"
                  label="Phone Number"
                  :rules="[required('Phone Number')]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="contactPerson"
                  prepend-icon="mdi-account"
                  label="Contact Person"
                  :rules="[required('Contact Person')]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="address"
                  prepend-icon="mdi-badge-account-horizontal"
                  label="Location"
                  :rules="[required('Location')]"
                ></v-text-field>
              </v-col>
              <v-col class="mt-4" cols="12" sm="6" md="6">
                <v-select
                  v-model="regionName"
                  :items="regions"
                  prepend-icon="mdi-map"
                  label="Select Region"
                  dense
                  item-text="name"
                  item-value="name"
                  :rules="[required('Region')]"
                ></v-select>
              </v-col>
              <v-col class="mt-1" cols="12" sm="6" md="6">
                <v-switch
                  v-model="rare_and_specialized"
                  value
                  label="Rare & Specialised Model"
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <div class="ma-auto pb-5">
              <v-btn
                rounded
                class="ma-5 pa-4"
                color="secondary"
                @click="inviteVendor"
                :loading="loading"
                :disabled="!valid"
                :dialogInvite="dialogInvite"
              >
                Invite Vendor
                <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
              </v-btn>

              <v-btn rounded class="ma-5 pa-4" color="" @click="clear" dark>
                Clear
                <v-icon dark right class="ml-2"> mdi-format-clear </v-icon>
              </v-btn>

              <v-btn
                rounded
                class="ma-5 pa-4"
                color="red"
                @click="vendorCancel"
                dark
              >
                Cancel
                <v-icon dark right> mdi-close </v-icon>
              </v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import validation from '@/utils/Validations'
export default {
  name: 'GarageOnboarding',
  props: ['regions'],
  data: () => ({
    valid: false,
    companyName: '',
    companyEmail: '',
    phoneNumber: '',
    contactPerson: '',
    regionName: '',
    address: '',
    rare_and_specialized: '',
    dialog: false,
    dialogInvite: false,
    alert: false,
    ...validation,
  }),

  computed: {
    ...mapState('garage', ['loading']),
  },

  methods: {
    async inviteVendor() {
      this.dialogInvite = true
      this.$store.state.loading = true

      const inviteData = {
        name: this.companyName,
        email: this.companyEmail,
        mobile_number: this.phoneNumber,
        contact_person: this.contactPerson,
        location: this.address,
        regionName: this.regionName,
      }
      await this.$store.dispatch('garage/inviteGarageVendor', inviteData)
      this.vendorCancel()
      this.$emit('refreshGarages')
    },

    vendorCancel() {
      this.dialog = false
      this.$refs.form.reset()
    },

    clear() {
      this.$refs.form.reset()
    },
  },
}
</script>
